import React from "react";
import { IFailable, failableAsync } from "ts-failable";
import { ApiProducerError, EventDetails, PassTransfer, PassifyApi, Sales } from "../../shared";
import { AuthContext } from "../auth";

type F<T> = IFailable<T, ApiProducerError>;
type P<T> = Promise<F<T>>;

export type ApiProducer = {
    getEvents: () => P<Array<EventDetails>>,
    getEvent: (eventId: string) => P<EventDetails>,
    getSalesBySession: (sessionId: string) => P<Sales>,
    getSalesBySegment: (sessionId: string, segmentId: string) => P<Sales>,
    getSalesByBatch: (sessionId: string, batchId: string) => P<Sales>,
    getTransferHistory: (orderId: string) => P<Array<PassTransfer>>,
}

const ApiProducerProvider = (): ApiProducer => {
    const { getAuth, refreshAuth } = React.useContext(AuthContext);

    const getEvents = async (): Promise<F<Array<EventDetails>>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getProducerEvents(auth.accessToken);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as Array<EventDetails>);
                }
            } else if(response.status === 401) {
                auth = run(await refreshAuth());
                return getEvents();
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const getEvent = async (eventId: string): Promise<F<EventDetails>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getProducerEvent(auth.accessToken, eventId);
            if(response.status === 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as EventDetails);
                }
            } else if(response.status === 401) {
                auth = run(await refreshAuth());
                return getEvent(eventId);
            } else if(response.status === 404) {
                return failure({ errorType: "EVENT_NOT_FOUND" })
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const getSalesBySession = async (sessionId: string): Promise<F<Sales>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getSalesBySession(auth.accessToken, sessionId);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as Sales);
                }
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return getSalesBySession(sessionId);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const getSalesBySegment = async (sessionId: string, segmentId: string): Promise<F<Sales>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getSalesBySegment(auth.accessToken, sessionId, segmentId);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as Sales);
                }
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return getSalesBySegment(sessionId, segmentId);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const getSalesByBatch = async (sessionId: string, batchId: string): Promise<F<Sales>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getSalesByBatch(auth.accessToken, sessionId, batchId);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as Sales);
                }
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return getSalesByBatch(sessionId, batchId);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    const getTransferHistory = async (orderId: string): Promise<F<Array<PassTransfer>>> => failableAsync(
        async ({ success, failure, run }) => {
            let auth = run(getAuth());
            const response = await PassifyApi.getTransferHistory(auth.accessToken, orderId);
            if(response.status == 200) {
                const responseJSON = await response.json();
                if(responseJSON["succeeded"]) {
                    return success(responseJSON["value"] as Array<PassTransfer>);
                }
            } else if(response.status == 401) {
                auth = run(await refreshAuth());
                return getTransferHistory(orderId);
            }
            return failure({ errorType: "FETCH_GENERIC_ERROR", error: "" });
        }
    );

    return {
        getEvents,
        getEvent,
        getSalesBySession,
        getSalesBySegment,
        getSalesByBatch,
        getTransferHistory,
    };
};

export { ApiProducerProvider };