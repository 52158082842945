import React from "react";
import { failableAsync } from "ts-failable";
import { ApiAuth, ApiAuthProvider, ApiEvent, ApiEventProvider, ApiProducer, ApiProducerProvider } from "./api/index";

function empty<T, U>() { return failableAsync<T, U>(async ({ failure }) => failure({ errorType: "UNINITIALIZED" } as U)); }

export type ApiState = {
    auth: ApiAuth,
    event: ApiEvent,
    producer: ApiProducer,
};

const initialApiState: ApiState = {
    auth: {
        signin: empty,
        signinWithFacebook: empty,
        signinWithGoogle: empty,
        register: empty,
        confirmEmail: empty,
        forgotPassword: empty,
        resetPassword: empty,
        changePassword: empty,
    },
    event: {
        getEvents: empty,
        getEventDetails: empty,
    },
    producer: {
        getEvents: empty,
        getEvent: empty,
        getSalesBySession: empty,
        getSalesBySegment: empty,
        getSalesByBatch: empty,
        getTransferHistory: empty,
    },
};

export const ApiContext = React.createContext(initialApiState);

export const ApiProvider = ({ children } : { children: React.ReactNode}) => {
    
    const auth = ApiAuthProvider();
    const event = ApiEventProvider();
    const producer = ApiProducerProvider();

    return (
        <ApiContext.Provider value={{ auth, event, producer }}>
            {children}
        </ApiContext.Provider>
    );
};