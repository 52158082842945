import { v4 } from "uuid";

export const SIGNIN_ID = v4();
export const SIGNUP_ID = v4();
export const FORGOT_PASSWORD_ID = v4();
export const RESET_PASSWORD_ID = v4();
export const CONFIRM_EMAIL_ID = v4();
export const EVENTS_ID = v4();
export const NEW_EVENT_ID = v4();
export const EDIT_EVENT_ID = v4();
export const SALES_DASHBOARD_ID = v4();