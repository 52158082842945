import React from "react";
import { EVENTS_ID, NEW_EVENT_ID, View } from "../../shared";
import { ScreenLoader } from "../../components";
import { EventForm } from "./eventform";
import "./neweventview.scss";
import { NavigationContext } from "../../contexts";

class NewEventView extends View {
    id = NEW_EVENT_ID;
    route = "/event/new";
	defaultRoute = true;
	authNeeded = true;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[EVENTS_ID]); },
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);

        this.navigation = React.useContext(NavigationContext);

        const init = () => {
            setFirstLoad(false);
        }

        React.useEffect(init, []);

        const createEvent = async () => {

        }

		return (firstLoad ?
			<ScreenLoader /> : <div id="new-event">
                <EventForm onSubmit={createEvent} />
			</div>
		);
	}
}

export { NewEventView };