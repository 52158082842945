import React from "react";
import { faClockRotateLeft, faBasketShopping, faUserGroup, faTicket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { SecondarySmallButton } from "../forms";
import { PassTransfer, PhoneFormatter, Sale, SoldPass } from "../../shared";
import { AccessTime, Mail, Person, PlayArrow, SwapHoriz, WhatsApp } from "@mui/icons-material";
import "./ticketsoldcard.scss";

export interface TicketSoldCardProps {
    sale: Sale,
    lazyLoadTransferHistory: () => Promise<Array<PassTransfer>>,
};

const TicketSoldCard = ({sale, lazyLoadTransferHistory}: TicketSoldCardProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [loadingPassTransfers, setLoadingPassTransfers] = React.useState<boolean>(false);
    const [passTransfers, setPassTransfers] = React.useState<Array<PassTransfer>>();

    const loadTransferHistory = () => {
        if(expanded && !passTransfers) {
            setLoadingPassTransfers(true);
            lazyLoadTransferHistory().then(history => {
                setPassTransfers(history);
                setLoadingPassTransfers(false);
            });
        }
    }

    React.useEffect(loadTransferHistory, [expanded]);

    const toggleExpand = (e: React.MouseEvent<HTMLDivElement>) => {
        setExpanded(oldExpanded => !oldExpanded);
        e.preventDefault();
    }

    const getPassesGroupedBySegment = () => {
        const passes: {[segmentName: string]: Array<SoldPass>} = {};
        for(let i = 0; i < sale.passes.length; i++) {
            const pass = sale.passes[i];
            if(!(pass.segmentName in passes)) {
                passes[pass.segmentName] = [];
            }
            passes[pass.segmentName].push(pass);
        }
        return passes;
    }

    const passesGroupedBySegment = getPassesGroupedBySegment();

    const phoneFormatter = new PhoneFormatter();
    const brl = Intl.NumberFormat('pt-BR', { style: "currency", currency: "BRL" })
    const quantity =  sale.passes.reduce((prev, cur) => prev + cur.quantity, 0);

    return (
        <div className={`ticket-sold-card ${sale.orderStatus !== "Completed" ? "invalid" : ""} ${expanded ? "expanded" : ""}`} onClick={toggleExpand}>
            {true ? <>
                <div className="ticket-sold-card-user">
                    <div className="ticket-sold-card-user-avatar">{sale.customerEmail ? <img src={sale.customerPicture} /> : <FontAwesomeIcon icon={faUser} />}</div>
                    <div className="ticket-sold-card-user-info" title={sale.customerName ? `${sale.customerName} <${sale.customerEmail}>` : sale.customerEmail}>
                        {(expanded || !!sale.customerName) && <div className="ticket-sold-card-user-info-name">{expanded && <span className="icon"><Person sx={{ width: 16 }} /></span>}{sale.customerName || <i>Nome não cadastrado</i>}</div>}
                        {(expanded || !sale.customerName) && <div className="ticket-sold-card-user-info-email">{expanded && <span className="icon"><Mail sx={{ width: 16 }} /></span>}{sale.customerEmail}</div>}
                        {expanded && <div className="ticket-sold-card-user-info-phone"><span className="icon"><WhatsApp sx={{ width: 16 }} /></span>{phoneFormatter.mask(sale.customerPhone) || <i>Telefone não cadastrado</i>}</div>}
                    </div>
                </div>
                <div className="ticket-sold-card-sale">
                    {expanded ?
                        <>
                            {Object.keys(passesGroupedBySegment).map(segmentName => <div className="ticket-sold-card-sale-segment">
                                <div className="ticket-sold-card-sale-segment-name">{segmentName}</div>
                                {passesGroupedBySegment[segmentName].map(pass => <div className="ticket-sold-card-sale-segment-batch">
                                    <div className="ticket-sold-card-sale-segment-batch-bought">{pass.quantity}x {pass.batchName} <span className="light">({brl.format(pass.price)})</span> {!!pass.promoCodeUsed && <span className="promo-code">{pass.promoCodeUsed}</span>}</div>
                                    <div className="ticket-sold-card-sale-segment-batch-amount">{brl.format(pass.quantity * pass.price)}</div>
                                </div>)}
                            </div>)}
                            <div className="ticket-sold-card-sale-payment">
                                <div className="ticket-sold-card-sale-payment-caption">Método de pagamento</div>
                                <div className="ticket-sold-card-sale-payment-method">
                                    {sale.paymentMethod === "Pix" ?
                                        <>
                                            <img src="/images/pix.svg" width="16" height="16" />
                                            PIX
                                        </> :
                                        sale.paymentMethod === "MercadoPago" ?
                                            <>
                                                <img src="/images/credit-card.svg" width="16" height="16" />
                                                Cartão de Crédito
                                            </> :
                                            <>
                                                Gratuidade
                                            </>
                                    }
                                    {sale.orderStatus === "Reimbursed" && <span className="light">Reembolsado</span>}
                                </div>
                            </div>
                            {loadingPassTransfers ?
                                <>Carregando... {/* trocar por loader animado */}</> :
                                !!passTransfers && passTransfers.reduce((prev, cur) => prev + cur.transfers.length, 0) > 0 &&
                                <div className="ticket-sold-card-sale-transfer-history">
                                    <div className="ticket-sold-card-sale-transfer-history-caption">Transferências</div>
                                    {passTransfers.map(passTransfer => <div className="ticket-sold-card-sale-transfer-history-list">
                                        {passTransfer.transfers.map(transfer => <div className="ticket-sold-card-sale-transfer-item">
                                            <SwapHoriz />
                                            <div className="ticket-sold-card-sale-transfer-item-peer">{transfer.senderCustomer.name ?? transfer.senderCustomer.phone ?? transfer.senderCustomer.email}</div>
                                            <PlayArrow />
                                            <div className="ticket-sold-card-sale-transfer-item-peer">{transfer.recipientCustomer.name ?? transfer.recipientCustomer.phone ?? transfer.recipientCustomer.email}</div>
                                            <div className="ticket-sold-card-sale-transfer-item-time">
                                                <AccessTime />
                                                {DateTime.fromMillis(Date.parse(transfer.updatedAt)).toRelative({ locale: "pt-br" })}
                                            </div>
                                        </div>)}
                                    </div>)}
                                </div>
                            }
                        </> : <>
                            <div className="ticket-sold-card-sale-quantity">{`${quantity} ingresso${quantity > 1 ? "s" : ""}`}</div>
                            <div className="ticket-sold-card-sale-financial">{brl.format(sale.passes.reduce((prev, cur) => prev + cur.quantity * cur.price, 0))}</div>
                        </>
                    }
                </div>
            </> : <div className="ticket-sold-card-container">
                <div className="ticket-sold-card-user">
                    <div className="ticket-sold-card-user-avatar">{sale.customerEmail ? <img src={sale.customerPicture} /> : <FontAwesomeIcon icon={faUser} />}</div>
                    <div className="ticket-sold-card-user-name" title={sale.customerName}>{sale.customerName}</div>
                </div>
                <div className="ticket-sold-card-body">
                    <div className="ticket-sold-card-ticket-details">
                        <div className="ticket-sold-card-ticket-bought">
                            <div className="ticket-sold-card-ticket-details-label">
                                <FontAwesomeIcon icon={faBasketShopping} />
                                Compra
                            </div>
                            <div className="ticket-sold-card-ticket-details-body">
                                {sale.boughtAt.toString()}
                            </div>
                        </div>
                        <div className="ticket-sold-card-ticket-batch">
                            <div className="ticket-sold-card-ticket-details-label">
                                <FontAwesomeIcon icon={faTicket} />
                                Lote
                            </div>
                            <div className="ticket-sold-card-ticket-details-body">
                            {
                            sale.passes[0]?.batchName ?? ""
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ticket-sold-card-footer">
                    <SecondarySmallButton content={<><FontAwesomeIcon icon={faClockRotateLeft} /> Ver Histórico do Usuário</>} onClick={async () => {}} />
                    <SecondarySmallButton content={<><FontAwesomeIcon icon={faUserGroup} /> Ver Perfis Semelhantes</>} onClick={async () => {}} />
                </div>
            </div>}
        </div>
    );
};

export { TicketSoldCard };