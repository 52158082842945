import React from "react";
import { DateTime } from "luxon";
import { AccessTime, AddCircleOutline, Edit, EventNote, LocationOn, Sell } from "@mui/icons-material";
import { ApiProducerError, EDIT_EVENT_ID, EVENTS_ID, EventList, NEW_EVENT_ID, SALES_DASHBOARD_ID, SIGNIN_ID, View } from "../../shared";
import { ApiContext, NavigationContext, ThemeContext } from "../../contexts";
import { ButtonSliderSwitch, ScreenLoader, Timeline } from "../../components";
import "./eventsview.scss";

enum EventState {
    all,
    active,
    inactive
}

class EventsView extends View {
    id = EVENTS_ID;
    route = "/event";
	defaultRoute = true;
	authNeeded = true;
    header = {
        backClick: null,
        supportClick: () => {}
    };
    render = () => {
        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [events, setEvents] = React.useState<EventList>([]);
        const [eventState, setEventState] = React.useState<EventState>(EventState.all);

        const api = React.useContext(ApiContext);
        const { views, goTo, location } = this.navigation = React.useContext(NavigationContext);
        const { theme } = React.useContext(ThemeContext);

        const handleError = <T,>(response: T) => {
            return (error: ApiProducerError) => {
                switch(error.errorType) {
                    case "NO_AUTH":
                    case "AUTH_EXPIRED":
                        goTo(views[SIGNIN_ID], undefined, { ref: location.pathname });
                        break;
                }
                return response;
            }
        }

        const loadEvents = async () => {
            const failableSales = await api.producer.getEvents();
            return failableSales.match({
                success: events => events,
                failure: handleError([])
            });
        }

        const init = () => {
            loadEvents().then(events => {
                setEvents(events);
                setFirstLoad(false);
            });
        }

        React.useEffect(init, []);

        const filteredEvents = events.filter(event =>
            eventState === EventState.all ||
            (eventState === EventState.active && Date.parse(event.startDate) >= new Date().getTime()) ||
            (eventState === EventState.inactive && Date.parse(event.startDate) < new Date().getTime())
        );

        const sortEvents = (events: EventList) => {
            return events.sort((a, b) => {
                if(Date.parse(a.startDate) > Date.parse(b.startDate)) {
                    return -1;
                } else if(Date.parse(a.startDate) < Date.parse(b.startDate)) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        const goToSalesDashboard = (eventId: string) => {
            return (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
                goTo(views[SALES_DASHBOARD_ID], { eventId: eventId });
                e.preventDefault();
            }
        }

        const createNewEvent = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
            goTo(views[NEW_EVENT_ID]);
            e.preventDefault();
        }

        const editEvent = (eventId: string) => {
            return (e: React.MouseEvent<HTMLButtonElement>) => {
                goTo(views[EDIT_EVENT_ID], { eventId: eventId });
                e.preventDefault();
            }
        }

		return (firstLoad ?
			<ScreenLoader /> : <div id="events">
                <div id="events-header">
                    <div id="events-header-text">Meus eventos</div>
                    <div id="events-header-filter">
                        <ButtonSliderSwitch index={eventState === EventState.active ? 1 : eventState === EventState.inactive ? 2 : 0} items={[{
                            content: "Todos",
                            onClick: (e) => {
                                setEventState(EventState.all);
                                e.preventDefault();
                            }
                        },{
                            content: "Próximos",
                            onClick: (e) => {
                                setEventState(EventState.active);
                                e.preventDefault();
                            }
                        },{
                            content: "Passados",
                            onClick: (e) => {
                                setEventState(EventState.inactive);
                                e.preventDefault();
                            }
                        }]} />
                    </div>
                </div>
                {filteredEvents.length > 0 ?
                    <div id="events-timeline">
                        <Timeline items={[
                            /*{
                                key: "Novo evento",
                                element: <div id="events-timeline-new"><button onClick={createNewEvent}><span className="icon"><AddCircleOutline /></span> Criar novo evento</button></div>,
                            },*/
                            ...sortEvents(filteredEvents).map(event => ({
                                key: DateTime.fromMillis(Date.parse(event.startDate)).toFormat("yyyy-LL-dd", { locale: "pt-br"}),
                                element: (
                                    <div className={`events-timeline-item ${Date.parse(event.startDate) < new Date().getTime() ? "past" : ""}`} onClick={goToSalesDashboard(event.slug || event.id)}>
                                        <div className="events-timeline-item-responsive-group">
                                            <div className="events-timeline-item-image">
                                                <img src={event.smallImageUrl} />
                                            </div>
                                            <div className="events-timeline-item-details">
                                                <div className="events-timeline-item-details-title">{event.name}</div>
                                                <div className="events-timeline-item-details-date"><span className="icon"><AccessTime sx={{ fontSize: "14px" }} /></span>{DateTime.fromMillis(Date.parse(event.startDate)).toFormat("ccc, d LLL yy", { locale: "pt-br" })}</div>
                                                <div className="events-timeline-item-details-location"><span className="icon"><LocationOn sx={{ fontSize: "14px" }} /></span>{event.venue?.name}</div>
                                            </div>
                                        </div>
                                        <div className="events-timeline-item-responsive-group">
                                            <div className="events-timeline-item-cta">
                                                <button onClick={editEvent(event.slug || event.id)}><Edit /></button>
                                                <button onClick={goToSalesDashboard(event.slug || event.id)}><Sell /></button>
                                            </div>
                                        </div>
                                    </div>
                                ),
                            }))
                        ]} />
                    </div> :
                    <div className="events-prompt">
                        <img src={`/images/${theme}/empty-tickets.svg`} />
                        {<>
                            <h3>Nenhum evento encontrado.</h3>
                            <p>Você não tem nenhum evento {eventState === EventState.active && "próximo"}{eventState === EventState.inactive && "passado"} cadastrado. Deseja criar um?</p>
                            <a onClick={createNewEvent}>Criar evento</a>
                        </>}
                    </div>
                }
			</div>
		);
	}
}

export { EventsView };