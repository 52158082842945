import React from "react";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { SALES_DASHBOARD_ID, RouteBuilder, RouteNavigator, RouteParams, SIGNIN_ID, View } from "../shared";
import { AuthContext } from "./auth";
import { ConfirmEmailView, EditEventView, EventsView, ForgotPasswordView, NewEventView, ResetPasswordView, SalesDashboardView, SigninView, SignupView } from "../views";
import { SalesDashboard2View } from "../views/sales/salesdashboardview copy";

interface ViewList {
    [key: string]: View
}

export type NavigationState = {
    navigate: NavigateFunction,
    location: Location,
    views: ViewList,
    goTo: (view: View, params?: RouteParams, searchParams?: RouteParams) => void,
    routeBuilder?: RouteBuilder,
    currentView?: View,
}

const initialNavigationState: NavigationState = {
    navigate: () => {},
    location: {
        state: "",
        key: "",
        pathname: "",
        search: "",
        hash: ""
    },
    views: {},
    goTo: () => {}
};

export const NavigationContext = React.createContext(initialNavigationState);

export const NavigationProvider = ({ children } : { children: React.ReactNode}) => {
    const navigate = useNavigate()
    const location = useLocation();

    const [currentView, setCurrentView] = React.useState<View>();
    const [routeBuilder, setRouteBuilder] = React.useState<RouteBuilder>();
    const [views, setViews] = React.useState<ViewList>({});

    const { auth } = React.useContext(AuthContext);

    const goTo = (section: View, params?: RouteParams, searchParams?: RouteParams) => {
        navigate(RouteNavigator.ParseRoute(section, params, searchParams));
    }

    const initViews = () => {
        const newViews: ViewList = {};
        [
            new SigninView(),
            new SignupView(),
            new ForgotPasswordView(),
            new ResetPasswordView(),
            new ConfirmEmailView(),
            new EventsView(),
            new NewEventView(),
            new EditEventView(),
            new SalesDashboardView(),
            new SalesDashboard2View()
        ].forEach(view => {
            newViews[view.id] = view;
        });
        setViews(newViews);
        setRouteBuilder(new RouteBuilder(newViews[SALES_DASHBOARD_ID], ...Object.values(newViews)));
        setCurrentView(newViews[SALES_DASHBOARD_ID]);
    }

    const loadView = () => {
        if(routeBuilder !== undefined) {
            const routeTarget = routeBuilder.find(location.pathname);

            if(typeof routeTarget === "string") {
                navigate(routeTarget);
            } else {
                setCurrentView(routeTarget);
                if(!auth && routeTarget.authNeeded) {
                    goTo(views[SIGNIN_ID], undefined, { ref: location.pathname });
                }
            }
        }
	}

    React.useEffect(initViews, []);
    React.useEffect(loadView, [routeBuilder, location]);

    return (
        <NavigationContext.Provider value={{ navigate, location, views, goTo, routeBuilder, currentView }}>
            {children}
        </NavigationContext.Provider>
    );
};