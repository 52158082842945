import React from "react";
import md5 from "md5";
import { DateTime } from "luxon";
import "./timeline.scss";

type TimelineKey = number | string;
type TimelineElement = JSX.Element | string;

interface TimelineItem<T = TimelineElement> {
    key: TimelineKey,
    element: T
};

type TimelineHashMap<T = TimelineElement> = {[hash: string]: Array<TimelineItem<T>>};

interface TimelineProps<T = TimelineElement> {
    items: Array<TimelineItem<T>>
} 

const Timeline = (props: TimelineProps) => {
    const humanizeDate = (key: TimelineKey) => {
        const now = DateTime.now();
        const date = DateTime.fromJSDate(new Date((typeof key === "string" ? Date.parse(key) : key) + new Date().getTimezoneOffset() * 60000));
        if(date.hasSame(now, "day")) {
            return <React.Fragment key="Hoje">Hoje</React.Fragment>;
        } else if(now > date) {
            return <React.Fragment key={date.toRelative({ unit: ["years", "months", "weeks", "days", "hours", "minutes", "seconds"], locale: "pt-br" })}>{date.toRelative({ unit: ["years", "months", "weeks", "days", "hours", "minutes", "seconds"], locale: "pt-br" })}</React.Fragment>
        } else {
            return <React.Fragment key={date.toFormat("dd 'de' LLLL", { locale: "pt-br" })}>{date.toFormat("dd 'de' LLLL", { locale: "pt-br" })} <span className="light">{date.toFormat("cccc", { locale: "pt-br" })}</span></React.Fragment>;
        }
    }

    const buildHashMap = () => {
        const newItems: TimelineHashMap = {};
        for(let i = 0; i < props.items.length; i++) {
            const item = props.items[i];

            const key = md5(humanizeDate(item.key).key?.toString() ?? "");
            if(!(key in newItems)) {
                newItems[key] = [];
            }
            newItems[key].push(item);
        }
        return newItems;
    }

    const hashMap = buildHashMap();

    return <div className="timeline">
        <div className="timeline-line"></div>
        {Object.keys(hashMap).map(key => <div key={key} className="timeline-item">
                <div className="timeline-item-header">
                    <div className="timeline-point-wrapper">
                        <div className="timeline-point"></div>
                    </div>
                    <div className="timeline-item-header-title">{humanizeDate(hashMap[key][0].key)}</div>
                </div>
                <div className="timeline-item-content">
                    {hashMap[key].map(item => item.element)}
                </div>
            </div>
        )}
    </div>;
}

export { Timeline };